import {useEffect, useState} from "react";
import {getSponsors} from "../API/API";
import {Card} from "antd";
import Meta from "antd/es/card/Meta";
import "./SponsorPage.css"
import "../css/General.css"
import no_image from './images/no_image.jpg';

export const SponsorPage = () => {

    const [sponsors, setSponsors] = useState([]);

    useEffect(() => {

        const fetchSponsors = async () => {
            setSponsors(await getSponsors());
        }

        fetchSponsors();
        console.log(sponsors);

    }, []);

    const cardStyles = {
        textDecoration: 'none', // Remove text underline
    };


    return (
        <div className='parent-div'>
            <div className='sponsor-div'>

                <div className='sponsor-wrap-div'>
                    <h1>Onze sponsors</h1>
                    <div className='parent-div'>
                        <div className='sponsor-div width-con'>
                            {
                                sponsors.map((sponsor) =>(
                                    <a href={'https://'+sponsor.link} target="_blank" rel="noopener noreferrer" style={cardStyles}>
                                        <Card
                                            hoverable
                                            style={{ width: 240 }}
                                            cover={
                                                <img 
                                                    alt="sponsor picture" 
                                                    src={sponsor.image || no_image} 
                                                    style={{ width: '240px', height: '140px' }}
                                                    onError={(e) => { e.target.src = no_image; }} 
                                                />
                                            }
                                        >
                                            <Meta title={sponsor.name}
                                                  description={
                                                      sponsor.street_name + ' ' + sponsor.street_number + '\n ' + sponsor.city + ' ' + sponsor.postal_code + '\n' + sponsor.link
                                                  } />
                                        </Card>
                                    </a>
                                ))
                            }
                        </div>
                    </div>
                    <h1>Word sponsor van VT Temse</h1>
                    <p>
                        Voor meer info over sponsoring neem contact op <a href="mailto:burt.riske@trends-business-information.be">Burt Riske</a>
                    </p>
                </div>
            </div>
        </div>
    )
}