import {Card} from "antd";
import "../css/General.css"
import "./FrontPage.css"
import {getTeamById} from "../API/API";
import {useEffect, useState} from "react";
import {CalendarOutlined, CarOutlined, HomeOutlined, ProfileOutlined} from "@ant-design/icons";
import { Space } from 'antd';

export const GameCard = (props) => {
    const [firstMatch, setFirstMatch] = useState([])

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTeam = async () => {
            const teamData = await getTeamById(props.id);
            var first = teamData.matches.find(item => item.resulthoofd.length === 0);
            setFirstMatch(first);
            setLoading(!loading);
        };

        fetchTeam();
    }, [props.id]);


    const customCardStyle = {
        width: '340px',
    };

    return (
        <div className='t-card-div'>
            <Card title={props.gender + " " + props.name || 'Loading...'} className="game-card" style={customCardStyle} size='large' loading={loading}>
                {firstMatch ? (
                    <div className='inner-card-div'>
                        <Space>
                            <HomeOutlined style={{ fontSize: '16px' }}/>
                            <span className='card-span'>{firstMatch?.thuisploeg}</span>
                        </Space>
                        <Space>
                            <CarOutlined style={{ fontSize: '16px' }}/>
                            <span className='card-span'>{firstMatch?.bezoekers}</span>
                        </Space>
                        {firstMatch?.sporthalafk && (
                            <Space>
                                <ProfileOutlined style={{ fontSize: '16px' }}/>
                                <span className='card-span'>{firstMatch?.sporthalafk} {firstMatch?.sporthalgemeente}</span>
                            </Space>
                        )}
                        <Space>
                            <CalendarOutlined style={{ fontSize: '16px' }}/>
                            <span className='card-span'>{firstMatch?.Datum} {firstMatch?.aanvangsuur}</span>
                        </Space>
                    </div>
                ) : (
                    <div className='no-match-available'>
                        <h1>Geen komende match beschikbaar</h1>
                    </div>
                )}
            </Card>
        </div>
    );
}