import {Card, Space} from "antd";
import Meta from "antd/es/card/Meta";
import {MailOutlined, PhoneOutlined} from "@ant-design/icons";

export const PersonCard = (props) => {

    return (
        <div className='board-card-wrap'>
            <Card alt={props.name} style={{ width: 300, justifyContent: "center" }} cover={<img src={props.image} style={{ height: 'auto', width: '300px' }} />} >
                <Meta title={props.name} description={props.description} />
                <div className='board-card-div'>
                    <Space>
                        {props.email !== "" && (
                        <>
                            <MailOutlined style={{ fontSize: '16px' }} />
                            <span>{props.email}</span>
                        </>
                        )}
                    </Space>
                    <Space>
                        {props.phone !== "" && (
                        <>
                            <PhoneOutlined style={{ fontSize: '16px' }} />
                            <span>{props.phone}</span>
                        </>
                        )}
                    </Space>
                </div>
            </Card>
        </div>
    )
}