import React from 'react';
import "../css/General.css"
import "../frontpage/GameCard.css"

const Insurance = () => {
  return (
    <div className='container'>
        <div style={{textAlign: 'left'}}>
            <h1>Een sportongeval gehad bij onze club?</h1>
            <p>Al onze leden zijn verzekerd voor sportongevallen..</p>
            <p>Volley Vlaanderen heeft voor sportongevallen een verzekering afgesloten bij ETHIAS (polisnummer 45.285.014).</p>
            <p>Het is belangrijk dat je het sportongeval aangeeft aan een verantwoordelijk van de club.</p>
            <p>Zij zullen u de nodige uitleg geven van wat je allemaal moet doen.</p>
            <p>Nog belangrijk is dat je onmiddellijk een dokter raadpleegt, neem zeker het blanco medisch attest mee (attest C).</p>
            <p>Heb je pas nadien last van het ongeval?</p>
            <p>Wanneer het sportongeval nog niet te lang geleden is, kan je dit nog steeds aangeven. Breng ook hier onmiddellijk jouw train(st)er en/of een verantwoordelijke binnen de club op de hoogte.</p>

            <h1>Hoe kan ik mijn sportongeval aangeven?</h1>
            <p>In de cafetaria aan de toog is er een map waar 'GUDO' op staat, hierin zitten altijd blanco medische attesten in om in te vullen.</p>
            <p>Nadat de dokter het medisch attest heeft ingebuld kan je dit doormailen naar gudo.vanlandeghem@gmail.com</p>
            
            <p>Geef info over het sportongeval telefonisch door aan Gudo via 03/889 57 31, 0496/47 40 72.</p>
            <p>Hij zal de aangifte dan online verwerken via het EXTRANET van ETHIAS.</p>
            <p>Na ingave van het ongeval op de website van Ethiqs zal je van Gudo een mail ontvangen met de gegevens over het ongeval (o.a. het dossiernummer).</p>

        </div>
    </div>
  );
};

export default Insurance;
